/***

**Module Name: client
 **File Name :  client.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains clients table details.
 ***/
 import React, { useState, useEffect, useContext } from "react";


import Footer from "../../components/dashboard/footer";
import Header from "../../components/dashboard/header";
import Sidebar from "../../components/dashboard/sidebar";
import tmdbApi from "../../api/tmdbApi";
import { useHistory, Link, useLocation } from "react-router-dom";
import axios from 'axios';
import SweetAlert from 'react-bootstrap-sweetalert';
import Modal from 'react-bootstrap/Modal';
import moment from "moment";
import SessionPopup from "../SessionPopup"
import Loader from "../../components/loader";
import Tooltip from '@mui/material/Tooltip'
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import TableLoader from "../../components/tableLoader";
import { contentContext } from "../../context/contentContext";
import DataTable from 'react-data-table-component';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

let { lambda, appname } = window.app;


const AllLeads = () => {
    const history = useHistory();
    const { state } = useLocation();
    const { search } = state || {};
    // const [toggle, setToggle] = useState(false);
    const [deals, setDeals] = useState("");
    const [perpage, setPerpage] = useState(10);
    const [success, setSuccess] = useState(false);
    const [sentMail, setSentEmail] = useState(false)
    const [image, setImg] = useState('');

    const [loaderEnable, setLoaderEnable] = useState(false);


    const [clientData, setClientData] = useState([])
    const [phonenumber, setPhoneNumber] = useState("");


    const [totalCount, setTotalCount] = useState("")
    const [data, setData] = useState([]);
    const [idc, setIdc] = useState("");

    const [buyerdata, setBuyerData] = useState([]);
    const [countries, setCountries] = useState('');
    const [account, setAccount] = useState([]);
    const [previousEmail, setPreviousEmail] = useState('');
    //const [currentPage, setcurrentPage] = useState(1);

    const [dealsmsg, setDealsMsg] = useState("");
    const [isChecked1, setIsChecked1] = useState(false);
    const [btnLoader, setBtnLoader] = useState("");

    // const [isLoading, setIsLoading] = useState(false);
    const [flag, setFlag] = useState(false);
    const [showSessionPopupup, setShowSessionPopupup] = useState(false);

    const [company, setCompany] = useState([]);
    // const [paginationnumber, setPagintionNumber] = useState({});

    const [popup, setShowPopup] = useState(false);
    const [showExists, setShowExists] = useState(false);
    const [addPopup, setAddPopup] = useState(false);
    const [conversationpopup, setConversationpopup] = useState(false);

    const [adminPopup, setAdminPopup] = useState(false);
    const handleClosePopup = () => setShowPopup(false);

    const handleCloseConversation = () => setConversationpopup(false)
    const handleCloseAddPopup = () => setAddPopup(false);
    const handleCloseAdminPopup = () => setAdminPopup(false);
    const [sortDirection, setSortDirection] = useState('asc');
    const [arrowdir, setArrowDir] = useState('down');
    const [num, setNum] = useState();
    const [tableErrMsg, setTableErrMsg] = useState(false);
    const [clientPopup, setClientPopup] = useState(false);


    const [leadError, setLeadError] = useState({});



    const { searchedFlag, activeSearch, setActiveSearch, setActiveMenuObj, addComments, flagg, setFlagg, setAddComments, leadSearch, setLeadSearch, clearIcon, setClearIcon, content, setContent, saveLeadId, setSaveLeadId, updateLead, setUpdateLead, activeconversationItem, leadsFormData, setLeadsFormData, addClientData, setAddClientData, filterLeads, setFilterLeads, setActiveconversationItem, tempLeadList, setTempLeadList, setSearchedFlag, isChecked, setIsChecked, conversationFormData, leadData, setLeadData, setConversationFormData, conversationData, setConversationData, allLeadsData, setAllLeadsData, isLoading, setNavigateToggle, setIsLoading, processObject, sortTableAlpha, arrow, sortTableByDate, userData, dealsearch, setDealSearch, dealsadvSearch, setDealsAdvSearch, currentPage, setcurrentPage, rowsPerPage, setRowsPerPage, currentPageNew, setCurrentPage, route, setRoute, usePrevious, setActiveMenuId, GetTimeActivity } = useContext(contentContext)

    const [notifyData, setNotifyData] = useState({})

    // var exportExcelData = ["kiran"]

    const prevRoute = usePrevious(route)
    const validateObj = userData && userData.permissions && userData.permissions.length > 0 && userData.permissions.filter(eachItem => eachItem.menu === "Contact Management")
    const subValDashboard = validateObj && validateObj[0] && validateObj[0].submenus && validateObj[0].submenus[1].dashboard
    useEffect(() => {
        if (prevRoute != undefined && prevRoute != route) {
            setCurrentPage(1)
            setRowsPerPage(15)
            setDealsAdvSearch({})
            setSearchedFlag(false);
            setDealSearch("")


        }



    }, [prevRoute]);

    useEffect(() => {

        if (window.site === undefined) {
            setTimeout(() => {
                if (window.site && window.site.common && window.site.common.imagesCDN) {
                    setImg(window.site.common.imagesCDN)
                }
            }, 1000);
        }
        if (window.site && window.site.common && window.site.common.imagesCDN) {
            setImg(window.site.common.imagesCDN)
        }

    }, [window.site]);

    const keyForRerender = `${rowsPerPage}_${data?.length}`;

    const GetCountries = async () => {
        try {

            const response = await tmdbApi.getLookUp({
                "type": ["country"],
                "sortBy": "alpha3",
                "projection": "tiny"
            });

            if (response?.result?.data == "Invalid token or Expired") {
                setShowSessionPopupup(true)
            } else {
                setCountries(response?.result?.data);
            }
        } catch {
        }
    };

    const columns = [

        {
            name: 'Name',
            selector: row => row?.clientname ?? "",
            sortable: true,
        },

        {
            name: 'Type',
            cell: (props) => <button type="button" className="btn btn-primary btn-sm btn-rounded " data-bs-toggle="modal" data-bs-target=".orderdetailsModal">
                {props?.clienttype === "BUYER" ? <span className="material-icons-outlined text-primary"> local_mall</span> : props?.clienttype === "SELLER" ? <span className="material-icons-outlined text-danger"> storefront</span> : <span className="material-icons-outlined text-success"> group </span>}
                {props?.clienttype}
            </button>,
            sortable: false,
        },
        {
            name: 'Account manager',
            selector: row => row?.accountmanager ?? "",
            sortable: true,
        },
        {
            name: 'Enquired On',
            // selector: row => moment(row.created).utc().format("DD-MMM-YYYY HH:mm"),
            selector: row => row?.created,
            cell: (props) => props?.created != undefined ? new Date(props?.created).toLocaleDateString('en-IN', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
            }) : '',
            sortable: true,
            // sortFunction: (a, b) => moment(a.created).diff(b.created),
        },
        {
            name: 'Followed On',
            // selector: row => moment(row.updated).utc().format("DD-MMM-YYYY HH:mm"),
            selector: row => row.updated,
            cell: (props) => props.updated != undefined ? new Date(props.updated).toLocaleDateString('en-IN', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
            }) : '',
            sortable: true,
            // sortFunction: (a, b) => moment(a.updated).diff(b.updated),
        },
        {
            name: 'Comments',
            // selector: row => row?.comments ?? '',
            cell: (props) => <Tooltip title={props?.comments} placement="top"><div className="text-elipsis">{props?.comments ?? ""}</div></Tooltip>,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row?.status ?? "",
            sortable: true,
        },


        {
            name: <>{(subValDashboard && subValDashboard.view && subValDashboard.view.display === true) || (subValDashboard && subValDashboard.edit && subValDashboard.edit.display === true) ? 'Actions' : null}</>,
            cell: (props) =>
                //   {
                subValDashboard && subValDashboard.view && subValDashboard.view.display === true &&

                <div className="d-flex">
                    {subValDashboard && subValDashboard.view && subValDashboard.view.display === true &&
                        <a onClick={e => handleViewDeal(e, props.enquiryid)} className={`${subValDashboard && subValDashboard.view && subValDashboard.view.enable === false || tempLeadList?.length <= 0 || userData?.userid !== leadData?.userid ? 'pe-none' : ''} text-success action-button`}><i className="mdi mdi-eye font-size-18"></i></a>}
                    {subValDashboard && subValDashboard.edit && subValDashboard.edit.display === true &&
                        <a onClick={e => handleEditDeal(e, props.enquiryid)} className={`${subValDashboard && subValDashboard.edit && subValDashboard.edit.enable === false || tempLeadList?.length <= 0 || userData?.userid !== leadData?.userid ? 'pe-none' : ''} text-danger action-button`}><i className="mdi mdi-pencil font-size-18"></i></a>}
                </div>

            //  }
            ,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];
    const handlePageChange = (page) => {
        GetTimeActivity()
        setCurrentPage(page);
    };

    const handlePerRowsChange = (newPerPage) => {
        GetTimeActivity()
        setRowsPerPage(newPerPage);
    };
    const notify = () => {
        toast.success('Sent email', {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
    const handleShowPopup = () => {
        GetTimeActivity()
        setShowPopup(true);
    };
    const GetCompany = () => {
        GetTimeActivity()
        const token = localStorage.getItem("token")
        const userid = localStorage.getItem("userId")
        axios({
            method: 'get',
            url: lambda + '/companylist?appname=' + appname + "&token=" + token + '&userid=' + userid + "&page=" + "leads",
        })
            .then(function (response) {
                if (response?.data?.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    // console.log("response", response?.data?.result);
                    setCompany(response?.data?.result?.data);

                    //  let arr = []

                    //  response?.data?.result?.data.forEach((item) => {
                    //    arr.push(item.companyname);


                    //  });

                    // const arrOfObj = response?.data?.result?.data.map((item) => {
                    //     return { value: item.companyname, label: item.companyname, id: item.companyid, accountmanager: item.accountmanager,status:item.status};
                    // });
                    // const arrOfObj2 = response?.data?.result?.individualCompanies.map((item) => {
                    //     return { value: item.companyname, label: item.companyname, id: item.companyid, accountmanager: item.accountmanager,status:item.status };
                    // });
                    // setCompanyData(arrOfObj);
                    // setIndividualCompaniesList(arrOfObj2);
                }

            });
    }
    const handleExport = (data1) => {
        // let k = defaultData || exportExcelData
        // let k = exportExcelData
        const modifiedArray = global.exportExcelData.map((each, i) => ({
            "S.NO": i + 1,
            "NAME": each?.clientname ?? "",
            "TYPE": each?.clienttype ?? "",
            "ACCOUNT MANAGER": each?.accountmanager ?? "",
            "ENQUIRED ON": each?.created ? new Date(each?.created).toLocaleDateString('en-IN', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',

            }) : "",

            "FOLLOWED ON": each?.updated ? new Date(each?.updated).toLocaleDateString('en-IN', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',

            }) : "",
            "COMMENTS": each?.comments ?? "",
            "STATUS": each?.status ?? "",



            // "STATUS": status,
        }));

        exportToExcel(modifiedArray, "data");

    }
    function exportToExcel(jsonData, fileName) {
        delete jsonData['_id'];
        const worksheet = XLSX.utils.json_to_sheet(jsonData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const data1 = new Blob([excelBuffer], { type: 'application/octet-stream' });
        //FileSaver.saveAs(data, `${fileName}.xlsx`);
        FileSaver.saveAs(data1, `DealsData.xlsx`);
    }
    const customSort = (rows, selector, direction) => {
        const sortedData = rows.slice().sort((rowA, rowB) => {
            const aField = String(selector(rowA)).toLowerCase();
            const bField = String(selector(rowB)).toLowerCase();

            let comparison = 0;

            if (aField > bField) {
                comparison = 1;
            } else if (aField < bField) {
                comparison = -1;
            }

            return direction === 'desc' ? comparison * -1 : comparison;
        });

        // Log the sorted data to the // console
        // setSortedRecomData(sortedData)
        global.exportExcelData = sortedData


        return sortedData;
    };
    useEffect(() => {
        if (!localStorage.getItem("token")) {
            history.push("/");
        }
        setActiveMenuId(300012)
        setActiveMenuObj({
            "Contact Management": true,
            "Client Management": false,
            "Reports": false
        })
        setRoute("leads")
        GetCountries();
        setNavigateToggle("")

        getBuyerList();
        GetCompany()
        getAccount();
        getAllLeadsData()

        userActivity();
    }, []);

    const userActivity = () => {
        let path = window.location.pathname.split("/");
        const pageName = path[path.length - 1];
        var presentTime = moment();
        let payload;
        setIsLoading(true)
        payload = {
            "userid": localStorage.getItem("userId"),
            "pagename": pageName,
            "pageurl": window.location.href,
            "starttime": presentTime,
            "useragent": JSON.parse(localStorage.getItem("loc"))

        };


        const previousId = localStorage.getItem("previousid");
        const urlLink = lambda + '/useractivity?appname=' + appname + (previousId ? "&previousid=" + previousId : "");


        axios({
            method: 'POST',
            url: urlLink,
            data: payload
        })
            .then(function (response) {
                if (response?.data?.statusCode === 200) {
                    localStorage.setItem("previousid", response?.data?.result)

                }
            });
    }
    const handleChangeMultiSelect = (selected, key) => {

        GetTimeActivity()

        let newContent = Object.assign({}, conversationFormData);
        setConversationFormData({ ...newContent, [key]: selected });
    }


    const handleChangeEmail = (selected, key) => {
        GetTimeActivity()


        let newContent = Object.assign({}, leadsFormData);
        console.log("selected", selected)
        setLeadsFormData({
            ...newContent,
            emailid: selected.value,
            phone: selected.phone ?? leadsFormData.phone,
            companyname: selected.companyname ?? leadsFormData.companyname,
            idc: selected.phone || selected.phone === "" ? (selected.phone.length > 0 ? selected.idc : '') : leadsFormData.idc,
            leadname: selected.name ?? leadsFormData.leadname,
            accountmanager: userData?.type === "ACCOUNTMANAGER" ? userData?.name  : (selected.accountmanager ?? "")
        
        });

     

    }
    console.log("leadsFormData----->", leadsFormData)
    const handleChangeCompanySelect = (selected) => {
        let newContent = Object.assign({}, addClientData);
        setAddClientData({
            ...newContent,

            companyname: selected.value ?? addClientData.companyname,

        });
    }
    const handleRemoveCompanyError = () => {
        setLeadError({})
    }
    const handleRemoveError = () => {
        setLeadError({})
    }
    useEffect(() => {
        if (searchedFlag) {
            handleSearch();
        }
    }, [searchedFlag]);

    const getAllDeals = async (clientId) => {
        GetTimeActivity()
        setIsLoading(true)
        const token = localStorage.getItem("token")


        const linkUrl = `${lambda}/clientenquiry?appname=${appname}&userid=${localStorage.getItem("userId")}&token=${token}&clientid=${clientId}`;
        const payload = {
            clientid: clientId
        }
        axios({
            method: 'POST',
            url: linkUrl,
            data: payload
        })
            .then(function (response) {

                if (response?.data?.result === "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {



                    if (response?.data?.result?.data?.length > 0) {
                        setData(response?.data?.result?.data);

                    } else {
                        setData([])
                    }
                    setTotalCount(response?.data?.result)

                    // global.exportExcelData = response?.data?.result?.data
                    setIsLoading(false)

                    setDeals(response?.data?.result);

                    setDealSearch("");
                    setSearchedFlag(false);

                }
            }).catch((err) => {
                setTableErrMsg(true)
                setIsLoading(false);
            });

    };

    const getBuyerList = (update) => {
        
        GetTimeActivity()
        const token = localStorage.getItem("token")
        const userid = localStorage.getItem("userId")
        axios({
            method: 'GET',
            url: lambda + '/clientType?appname=' + appname + "&token=" + token + '&userId=' + userid,
        })
            .then(function (response) {
              
                if (response?.data?.result === "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    if(userData?.type==="ACCOUNTMANAGER"){
                        
                        const data = response?.data?.result?.data?.filter((item) => 
                            item.companyData?.[0]?.accountmanager?item.companyData?.[0]?.accountmanager === userData?.name : item?.accountmanager === userData?.name
                          );
                          setBuyerData(data);
                          
                        console.log("data----------------->",data)
                       
                     
                    }else if(userData?.type==="MANAGER"){
                       
                          setBuyerData(response?.data?.result?.data);
                    }
                    else{
                        setBuyerData(response?.data?.result?.data);
                       
                    }
                

                }

            });
    }

    console.log("leadsFormData------------->",leadsFormData)

    const handleEditDeal = (e, id) => {
        GetTimeActivity()
        history.push("/editdeals/" + id);
    }

    const handleViewDeal = (e, id) => {
        GetTimeActivity()
        history.push("/viewdeals/" + id);
    }

    const handleSearch = (e, flagggg) => {
        GetTimeActivity()


        if (flagggg === "normalsearch" || flagggg === "click") {
            setSearchedFlag(true)
        }

        setFlag(true)
        if (dealsearch === "" && dealsadvSearch.length <= 0) {
            getAllDeals();
        }
        else {
            if (flagggg === "normalsearch") {

            }
            const token = localStorage.getItem("token")
            let urlLink;
            if (dealsearch) {
                urlLink = lambda + '/clientenquiry?appname=' + appname + "&userid=" + localStorage.getItem("userId") + "&search=" + dealsearch + "&token=" + token
            } else {
                urlLink = lambda + '/clientenquiry?appname=' + appname + "&userid=" + localStorage.getItem("userId") + "&token=" + token
            }
            const result = processObject(dealsadvSearch);
            setDealsAdvSearch(result)
            axios({
                method: 'POST',
                url: urlLink,
                data: result,
            })
                .then(function (response) {
                    if (response?.data?.result == "Invalid token or Expired") {
                        setShowSessionPopupup(true)
                        setShowPopup(false)
                        setLoaderEnable(false)
                    } else {

                        setDeals(response?.data?.result);
                        // setData(response?.data?.result?.data);
                        // setTimeout(function () { global.exportExcelData = response?.data?.result?.data }, 1000);
                        global.exportExcelData = response?.data?.result?.data



                        // setIsLoading(false)
                        setShowPopup(false)
                        setLoaderEnable(false);
                        if (flagggg === "click") {
                            setDealSearch("")
                        }
                    }
                }).catch((err) => {

                    setTableErrMsg(true)

                });
        }
    }

    const handleChange = (e) => {

        if (e.target.value === "") {
            // getAllDeals();
            setFlag(false)
        }
        setDealSearch(e.target.value)
    }

    function onConfirm() {
        GetTimeActivity()
        setSuccess(false);
        setDealSearch("")
    };
    function onConfirmUpdate() {
        GetTimeActivity()
        setUpdateLead(false)
    }
    function onConfirm1() {
        GetTimeActivity()
        setShowExists(false);
    };
    const handleAddDeal = (e) => {
        GetTimeActivity()
        setLeadError({})

        history.push("/createdeal")
    }
    const handleAddLead = () => {
        GetTimeActivity()
        setAddPopup(true)
        if(userData?.type==="ACCOUNTMANAGER"){
            setIsChecked1(true)
            setLeadsFormData({ leadname: "", enquirydate: "", phone: "", idc: "", leadclassification: "", emailid: "", companyname: [""], contactdate: "",accountmanager:userData?.name})
        }else{
            setIsChecked1(false)
            setLeadsFormData({ leadname: "", enquirydate: "", phone: "", idc: "", leadclassification: "", emailid: "", companyname: [""], contactdate: ""})
        }
        setLeadError({})
       

    }
    const handleActiveIcon = (e) => {
        setActiveSearch(true)
        setClearIcon(true)
    }
    const handleCloseClient = () => {
        setClientPopup(false)
        setAddClientData({})
        setLeadError({})
    };
    const handlAddConversation = (e) => {
        setConversationFormData({ subject: "", leadclassification: "", enquirydate: "", contactdate: "", modeofcommunication: "", additionalcontacts: [], conversationsummary: "" })
        setConversationpopup(true)
        setLeadError({})
    }

    const clearSearch = () => {
        GetTimeActivity()
        setDealSearch("");
        getAllDeals();
        setDealsAdvSearch({})
        setcurrentPage(1);
        setSearchedFlag(false)
        localStorage.removeItem("deals")
    }
    const clearLeadsData = () => {
        GetTimeActivity();
    
        setLeadsFormData({
            ...leadsFormData,
            enquirydate: "",
            phone: "",
            idc: "",
            leadname: "",
            leadclassification: "",
            companyname: "",
            contactdate: "",
            emailid: "",
            accountmanager: userData?.type !== "ACCOUNTMANAGER" ? "" : leadsFormData.accountmanager  // Set accountmanager only if userData.type is not "ACCOUNTMANAGER"
        });
    
        // Conditionally set isChecked1 to false if the user type is not "ACCOUNTMANAGER"
        if (userData?.type === "ACCOUNTMANAGER") {
            setIsChecked1(true);
        }else{
            setIsChecked1(false)
        }
    
        // Clear lead errors
        setLeadError({});
    };
    
    const clearNotifyData = () => {
        setNotifyData({ ...notifyData, accountmanager: "", companyname: "", clienttype: "", entity: "", companytype: "", agreement: false, signednda: false, commissiontype: "", commissionpercentage: "" })
        setLeadError({})
    }
    const clearConversionData = () => {
        GetTimeActivity()
        setConversationFormData({ ...conversationData, subject: "", leadclassification: "", enquirydate: "", contactdate: "", modeofcommunication: "", additionalcontacts: [], conversationsummary: "" })
        setLeadError({})
    }
    const clearClientData = () => {
        GetTimeActivity()
        setAddClientData({ type: "", entity: "", companyname: "" })
        setLeadError({})
    }
    const handleKeypress = (e) => {
        GetTimeActivity()

        if ((e.key === "Enter")) {
            setTimeout(function () {
                handleSearch();
            }, 100);
        }
    };



    const validateData = async () => {
        GetTimeActivity()
        let formIsValid = true;
        let error = { ...leadError };
        let mandatoryFileds = [{ name: 'Contact Name', key: 'leadname' }];

        if (mandatoryFileds) {
            mandatoryFileds.forEach(function (item) {
                if (
                    (leadsFormData[item.key]?.trim() === "" ||
                        leadsFormData[item.key] == undefined ||
                        leadsFormData[item.key] == "undefined")

                ) {
                    error[item.key] = item.name + " is required";
                    formIsValid = false;
                }
            });
        }

        if ( isChecked1 &&!leadsFormData?.accountmanager && leadsFormData?.accountmanager==="") {
            error['accountmanager'] = `Select accountmanager`;
            formIsValid = false;
        }
        if (!leadsFormData?.phone && leadsFormData?.idc) {
            error['phone'] = `Enter phone number`;
            formIsValid = false;
        }

        if (leadsFormData?.phone && !leadsFormData?.idc) {
            error['idc'] = `Select country code`;
            formIsValid = false;
        }

        if (leadsFormData?.emailid === undefined || leadsFormData?.emailid === "" || leadsFormData?.emailid?.length <= 0) {
            error['emailid'] = `Select or Enter email id`;
            formIsValid = false;
        } else {
            const regEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!regEx.test(leadsFormData?.emailid)) {
                error['emailid'] = `Please Enter Valid Email`;
                formIsValid = false;
            } else {
                error['emailid'] = '';
            }
        }

        if (!leadsFormData?.leadclassification || leadsFormData?.leadclassification === "") {
            error['leadclassification'] = `Select contact classification`;
            formIsValid = false;
        }



        setLeadError(error);
        return formIsValid;
    };


    const validateConvData = () => {
        GetTimeActivity()
        let formIsValid = true;
        let error = { ...leadError };

        if (!conversationFormData?.subject || conversationFormData?.subject?.trim() === "") {
            error['subject'] = `Enter subject`;
            formIsValid = false;
        }
        if (!conversationFormData?.contactdate || conversationFormData?.cantactdate?.trim() === "" || conversationFormData?.contactdate === "Invalid Date") {
            error['contactdate'] = `Enter contact date`;
            formIsValid = false;
        }
        if (!conversationFormData?.modeofcommunication || conversationFormData?.modeofcommunication?.trim() === "") {
            error['modeofcommunication'] = `Select mode of communication`;
            formIsValid = false;
        }
        if (!conversationFormData?.conversationsummary || conversationFormData?.conversationsummary?.trim() === "") {
            error['conversationsummary'] = `Enter conversation summary`;
            formIsValid = false;
        }


        setLeadError(error);
        return formIsValid
    }
    const validateClientData = () => {
        GetTimeActivity()
        let formIsValid = true;
        let error = { ...leadError };

        if (!addClientData?.type || addClientData?.type === "") {
            error['type'] = `Select client type`;
            formIsValid = false;
        }


        if (!addClientData?.entity || addClientData?.entity === "") {
            error['entity'] = `Select entity`;
            formIsValid = false;
        }
        if (!addClientData?.companyname || String.prototype.trim.call(addClientData?.companyname) === "") {
            if (addClientData?.entity === "INDIVIDUAL") {
                error['companyname'] = `Enter company name`;
            } else {
                error['companyname'] = `Select company name`;
            }

            formIsValid = false;
        }



        setLeadError(error);
        return formIsValid
    }
    const validateConvCommentsData = () => {
        GetTimeActivity()
        let formIsValid = true;
        let error = { ...leadError };


        if (!addComments?.comment || addComments?.comment?.trim() === "") {
            error['comment'] = `Enter follow up comment`;
            formIsValid = false;
        }
        if (!addComments?.nextfollowpdate || addComments?.nextfollowpdate.trim() === "" || addComments?.nextfollowpdate === "Invalid Date") {
            error['nextfollowpdate'] = `Select next follow up date`;
            formIsValid = false;
        }

        if (!addComments?.modeofcommuniciation || addComments?.modeofcommuniciation === "") {
            error['modeofcommuniciation'] = `Select mode of communication`;
            formIsValid = false;
        }


        setLeadError(error);
        return formIsValid
    }
    const validateContactData = () => {
        let formIsValid = true;
        let error = { ...leadError };
        if (!leadData?.phone && leadData?.idc) {
            error['phonenumber'] = `Enter phone number`;
            formIsValid = false;
        }


        if (leadData?.phone && !leadData?.idc) {
            error['Idc'] = `Select country code`;
            formIsValid = false;
        }
        setLeadError(error);
        return formIsValid
    }
    const validateNotifyData = () => {
        GetTimeActivity()
        let formIsValid = true;
        let error = { ...leadError };

        if (!notifyData?.companyname || String.prototype.trim.call(notifyData?.companyname) === "") {
            error['companyname'] = `Enter company name`;
            formIsValid = false;
        }


        if (!notifyData?.clienttype || notifyData?.clienttype === "") {
            error['clienttype'] = `Select content type`;
            formIsValid = false;
        }
        if (!notifyData?.companytype || notifyData?.companytype === "") {
            error['companytype'] = `Select company type`;
            formIsValid = false;
        }
        if (!notifyData?.entity || notifyData?.entity === "") {
            error['entity'] = `Select entity`;
            formIsValid = false;
        }
        if (!notifyData?.accountmanager || notifyData?.accountmanager === "") {
            error['accountmanager'] = `Enter accountmanager name`;
            formIsValid = false;
        }


        setLeadError(error);
        return formIsValid
    }
    const handleValidate = (e) => {
        GetTimeActivity()

        const isValid = validateData();
        isValid.then(result => {
            if (result === true) {
                handleAddFormData()
            }
        }).catch(error => {

        });
    }
    const handleClickSendEmail = async () => {

        const isValid = validateNotifyData();

        if (isValid) {

            GetTimeActivity()
            setBtnLoader("send")

            try {
                let payload = {
                    "companyname": notifyData?.companyname,
                    "accountmanager": notifyData?.accountmanager,
                    "clienttype": notifyData?.clienttype,
                    "companytype": notifyData?.companytype,
                    "entity": notifyData?.entity,
                    "agreement": notifyData?.agreement,
                    "signednda": notifyData?.signednda,
                    "commissiontype": notifyData?.commissiontype,
                    "commissionpercentage": notifyData?.commissionpercentage && notifyData.commissionpercentage.length > 0
                        ? `${notifyData.commissionpercentage}%`
                        : notifyData?.commissionpercentage,
                    leadid: saveLeadId
                };

                const response = await tmdbApi.NotifyAdmin(payload);


                if (response?.statusCode == 200) {
                    if (response?.result == "Invalid token or Expired") {
                        setShowSessionPopupup(true)
                    } else if (response?.result == "lead already exists") {
                        setShowExists(true)
                    } else {

                        getAllLeadsData();
                        // setSuccess(true);
                        // setAllDealsData({})
                        // setContentData({})

                        setLeadsFormData({})


                        setAdminPopup(false)

                        setBtnLoader("")
                        notify()
                        // setSaveLoaderEnable(false);
                    }
                }

            } catch {

            } finally {
                setBtnLoader("")
            }

        }
    }

    const handleAddFormData = async () => {
 // Filter the account list to find the user corresponding to the selected account manager
 const filterData = account?.filter((item) => item?.username === leadsFormData?.accountmanager) || [];
 
 // Determine the userId based on whether the checkbox is checked
 const userId = isChecked1 ? filterData?.[0]?.userid : localStorage.getItem("userId");

        GetTimeActivity()
        const updatedLeadsFormData = {
            ...leadsFormData,  // Spread existing form data
            createdBy: {
                userid: localStorage.getItem("userId"),  // Set userid (replace with dynamic value if necessary)
                name: userData?.name  // Set name (replace with dynamic value if necessary)
            },
            userid:userId
        };
        setBtnLoader("adding")
        try {

            const response = await tmdbApi.AddLead(updatedLeadsFormData);


            if (response?.statusCode == 200) {
                if (response?.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else if (response?.result == "lead already exists") {
                    setShowExists(true)
                } else {

                    getAllLeadsData(response?.result);
                    // setSuccess(true);
                    // setAllDealsData({})
                    // setContentData({})

                    setLeadsFormData({})


                    setAddPopup(false)

                    setBtnLoader("")
                    // setSaveLoaderEnable(false);
                }
            }

        } catch {

        } finally {
            setBtnLoader("")
        }

    };

    const handleConversationData = async (data) => {


        GetTimeActivity()
        setBtnLoader("add")

        const isValid = validateConvData();
        if (isValid) {

            setLoaderEnable(true);
            try {
                let payload = {
                    "additionalcontacts": conversationFormData?.additionalcontacts ?? [],

                    "contactdate": conversationFormData?.contactdate,
                    "conversationsummary": conversationFormData?.conversationsummary,

                    "enquirydate": conversationFormData?.enquirydate,
                    "leadclassification": conversationFormData?.leadclassification,



                    "modeofcommunication": conversationFormData?.modeofcommunication,

                    "subject": conversationFormData?.subject, leadid: data,
                    "status": "open"

                }
                const response = await tmdbApi.AddConversation(payload);

                if (response?.statusCode == 200) {
                    if (response?.result == "Invalid token or Expired") {
                        setShowSessionPopupup(true)
                    } else if (response?.result == "lead already exists") {
                        setShowExists(true)
                    } else {


                        // setSuccess(true);
                        // setAllDealsData({})
                        // setContentData({})

                        setConversationFormData({})
                        getConversations(data)
                        setLoaderEnable(false);
                        setConversationpopup(false)
                        // getAllLeadsData()
                        setAddPopup(false)

                        setBtnLoader("")
                        // setSaveLoaderEnable(false);
                    }
                }

            } catch {

            } finally {
                setBtnLoader("")
            }
        }
    };
    const handleClientAdd = async (value) => {

        const isValid = validateClientData();
        if (isValid) {
            setLoaderEnable(true)

            try {
                const payload = {
                    "personalemail": leadData?.emailid,
                    "name": leadData?.leadname,
                    "phone": leadData?.phone ?? "",
                    "type": addClientData?.type,
                    "leadid": saveLeadId,
                    "companyname": addClientData?.companyname,
                    "entity": addClientData?.entity,
                    "source": "leadmanagement",


                }
                const response = await tmdbApi.SaveClientData(payload);

                if (response?.statusCode == 200) {
                    if (response?.result == "Invalid token or Expired") {
                        setShowSessionPopupup(true)
                    } else if (response?.result == "lead already exists") {
                        setShowExists(true)
                    } else {


                        getAllLeadsData(value)

                        // getBuyerList(value);
                        setClientPopup(false)

                        // setSaveLoaderEnable(false);
                    }
                }

            } catch {

            } finally {
                setBtnLoader("")
            }
        }


    }




    const client = (data) => {

        GetTimeActivity()
        let page = 1;
        //  setCurrentPage(1);
        // setIsLoading(true);
        const token = localStorage.getItem("token")
        const userid = localStorage.getItem("userId")

        axios({
            method: 'POST',
            url: lambda + '/clients?appname=' + appname + "&token=" + token + "&userid=" + userid,
        })
            .then(function (response) {

                if (response?.data?.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    // setData(response?.data?.result?.data);
                    // global.exportExcelData = response?.data?.result?.data


                    setContent(response?.data?.result?.data);
                    const filterData = response?.data?.result?.data?.filter((item) => item.name === data?.leadname)

                    setClientData(filterData)

                    // setIsLoading(false);
                    // setClientMangeSearch("");
                    setSearchedFlag(false);
                }

            }).catch((err) => {
                console.log('error', err);
                setTableErrMsg(true)
                setIsLoading(false);
            });
    }

    const getDisplayText = (value) => {
        if (value) {
            const timestamp = new Date(value);
            const currentDate = new Date();

            // Compare dates without considering time
            const timestampDate = new Date(timestamp.getFullYear(), timestamp.getMonth(), timestamp.getDate());
            const currentDateDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

            const diffTime = Math.abs(currentDateDate - timestampDate);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

            if (diffDays === 0) {
                return 'Today';
            } else if (diffDays === 1) {
                return 'Yesterday';
            } else {
                return new Date(value).toLocaleDateString('en-IN', {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                });
            }
        } else {
            return '';
        }
    };
    const getConversations = async (id) => {
        // setIsLoading(true)
        GetTimeActivity()

        // setSaveLoaderEnable(true);
        // setButtonDisabled(true);
        try {
            let payload = {
                leadid: id
            }
            const response = await tmdbApi.GetConversations(payload);

            if (response?.statusCode == 200) {
                if (response?.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    // setSuccess(true);
                    // setAllDealsData({})
                    // setContentData({})

                    setLeadsFormData({})
                    setConversationData(response?.result)

                    setActiveconversationItem(response?.result?.[0])

                    const data = response?.result?.[0].status
                    if (data === "open") {
                        setIsChecked(false)
                    } else {
                        setIsChecked(true)
                    }
                    // setIsLoading(false)


                    // setErrors({})
                    // setSaveLoaderEnable(false);
                }
            }

        } catch {

        }
    }




    const getAllLeadsData = async (data) => {
        GetTimeActivity()
        setIsLoading(true)


        // setSaveLoaderEnable(true);
        // setButtonDisabled(true);
        try {
            const response = await tmdbApi.GetLeads();

            if (response?.statusCode === 200) {
                if (response?.result === "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    // setSuccess(true);
                    // setAllDealsData({})  
                    // setContentData({})


                    if (data === "update") {

                        const leadData = response?.result?.data?.filter((item) => item?.leadid === saveLeadId)

                        if (leadSearch?.length > 0) {

                            const value = filterLeads?.filter((item) => item.leadid === saveLeadId)
                            setSaveLeadId(value?.[0]?.leadid)
                            setTempLeadList(filterLeads)
                            getConversations(value?.[0]?.leadid)
                            setAllLeadsData(response?.result?.data)
                            setLeadData(leadData?.[0])
                            setAddClientData({ type: "", entity: "", companyname: "" })
                            setUpdateLead(true)
                            setLoaderEnable(false)
                            setBtnLoader("")
                            setIsLoading(false)
                        } else {


                            setSaveLeadId(leadData?.[0]?.leadid)
                            setPhoneNumber(leadData?.[0]?.phone)

                            setIdc(leadData?.[0]?.idc)
                            getConversations(leadData?.[0]?.leadid)
                            setAllLeadsData(response?.result?.data);
                            let data = response?.result?.data;

                            let index = data.indexOf(leadData?.[0]);
                            if (index !== -1) {
                                data.splice(index, 1);
                                data.unshift(leadData?.[0]);
                            }

                            setTempLeadList(data);

                            setUpdateLead(true)
                            setLoaderEnable(false)
                            setLeadData(leadData?.[0])
                            setAddClientData({ type: "", entity: "", companyname: "" })
                            setBtnLoader("")
                            setIsLoading(false)
                        }

                    } else {

                        setLeadsFormData({})



                        // setLeadSerach("")
                        setIsLoading(false)
                        if (flagg && leadSearch?.length <= 0) {
                            const data = response?.result?.data?.filter((item) => item.clientid === flagg)
                            const item = response?.result?.data;

                            let index = item.indexOf(data?.[0]);
                            if (index !== -1) {
                                item.splice(index, 1);
                                item.unshift(data?.[0]);
                            }
                            setTempLeadList(item)
                            setSaveLeadId(data?.[0]?.leadid)
                            setAllLeadsData(response?.result?.data)
                            setLeadData(response?.result?.data?.[0])
                            client(response?.result?.data?.[0])

                        } else if (leadSearch?.length > 0) {
                            const value = filterLeads?.filter((item) => item.leadid === saveLeadId)
                            setSaveLeadId(value?.[0]?.leadid)
                            setTempLeadList(filterLeads)
                            getConversations(value?.[0]?.leadid)
                            setAllLeadsData(response?.result?.data)
                            client(value?.[0])

                        } else {
                            setTempLeadList(response?.result?.data)
                            setSaveLeadId(response?.result?.data?.[0]?.leadid)
                            setAllLeadsData(response?.result?.data)
                            setLeadData(response?.result?.data?.[0])
                            getConversations(response?.result?.data?.[0]?.leadid);
                            client(response?.result?.data?.[0])
                        }

                        // setLeadSerach(...leadSearch)

                        // setIdc(response?.result?.data?.[0]?.idc)
                        // setPhoneNumber(response?.result?.data?.[0]?.phone)


                        const Id = response?.result?.data?.[0]?.clientid






                        setLoaderEnable(false)
                        if (flagg?.length > 0) {
                            getAllDeals(flagg)
                            // setClientData(client)
                        } else if (Id) {
                            getAllDeals(Id)
                        }
                        else {
                            getAllDeals()
                        }


                    }










                    // setErrors({})
                    // setSaveLoaderEnable(false);
                }
            }
            // setIsLoading(false)
        } catch {

            setIsLoading(false)
        }
    };



    const handleAddConversation = (item, index) => {
        // setIsLoading(true)

        setSaveLeadId(item?.leadid)

        // setIdc(item?.idc)
        setAddComments({ nextfollowpdate: "", comment: "", modeofcommuniciation: "" })
        if (filterLeads.length <= 0) {

            const allLeadsData11 = allLeadsData
            const clickedList = allLeadsData11.splice(index, 1)[0];
            setAllLeadsData([clickedList, ...allLeadsData11]);
            setTempLeadList([clickedList, ...allLeadsData11]);

        }

        const filterArr = allLeadsData.filter(eachItem => eachItem?.leadid === item?.leadid)
        setFlagg(item.clientid)
        setLeadData(item)

        getAllDeals(item.clientid)
        // setClientData(item.clientid)

        setLeadError({})
        getConversations(item?.leadid)
        client(item)
    }

    const handleConversationClick = (item, index) => {
        if (tempLeadList?.length > 0) {


            // const clickedList = conversationData.splice(index, 1)[0];
            // setConversationData([clickedList, ...conversationData]);
            setActiveconversationItem(item)
            if (item && item?.status === "open") {
                setIsChecked(false);
            } else {
                setIsChecked(true);
            }

            setAddComments({ nextfollowpdate: "", comment: "", modeofcommuniciation: "" })
            // setActiveSearch("active")
            setLeadError({})
        }
    }
    const getAccount = async () => {
        GetTimeActivity()
        try {
            const response = await tmdbApi.getAccount({
            });
            if (response?.result == "Invalid token or Expired") {      
                setShowSessionPopupup(true)
            } else {
                setAccount(response?.result);
            }

        } catch {
            console.log("error");
        }
    };

    const handleAddComments = async () => {

        GetTimeActivity()
        const isValid = validateConvCommentsData()
        if (isValid) {
            setBtnLoader("added")

            try {
                let payload = {
                    ...addComments, conversationid: activeconversationItem?.conversationid
                }
                const response = await tmdbApi.GetConversationComments(payload);

                if (response?.statusCode == 200) {
                    if (response?.result == "Invalid token or Expired") {
                        setShowSessionPopupup(true)
                    } else {
                        // setSuccess(true);
                        // setAllDealsData({})
                        // setContentData({})

                        conversationCommentsData(saveLeadId)

                        // setLeadsFormData({})
                        // setAllLeadsData(response?.result?.data)
                        // const result = response?.result?.data?.[0]?.leadid
                        // if (result) {
                        //     setActiveconversationItem(response?.result?.data?.[0])
                        //     setSaveLeadId(response?.result?.data?.[0]?.leadid)
                        //     setResponseLeadId(result)
                        //     getConversations(result);

                        // }


                        // setErrors({})
                        // setSaveLoaderEnable(false);
                    }
                }

            } catch {

            }
        }
    }
    // setSaveLoaderEnable(true);
    // setButtonDisabled(true);

    const conversationCommentsData = async (data) => {
        GetTimeActivity()

        // setSaveLoaderEnable(true);
        // setButtonDisabled(true);
        try {
            let payload = { leadid: data }
            const response = await tmdbApi.GetConversations(payload);

            if (response?.statusCode == 200) {
                if (response?.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    // setSuccess(true);
                    // setAllDealsData({})
                    // setContentData({})
                    setConversationData(response?.result)


                    const ConvId = activeconversationItem?.conversationid
                    const activeData = response?.result?.filter((item) => item.conversationid === ConvId)

                    setActiveconversationItem(activeData[0])
                    // const item = response?.result?.[0].status
                    // if (item === "open") {
                    //     setIsChecked(false)
                    // } else {
                    //     setIsChecked(true)
                    // }
                    setAddComments({ nextfollowpdate: "", comment: "", modeofcommuniciation: "" })
                    setBtnLoader("")
                    // setLeadsFormData({})

                    // setActiveconversationItem(response?.result?.[0])

                    // setErrors({})
                    // setSaveLoaderEnable(false);
                }
            }

        } catch {

        }

    }
    const AddDeal = () => {
        setNavigateToggle("leads");
        history.push("/createdeal")
    }

    const updateLeadData = async (value) => {

        const isValid = validateContactData();
        if (isValid) {
            setLeadError({})
            setBtnLoader("update")
            try {
                let payload = { phone: leadData?.phone, leadid: saveLeadId, idc: leadData?.idc, clientid: leadData?.clientid }
                const response = await tmdbApi.UpdateLead(payload);

                if (response?.statusCode == 200) {
                    if (response?.result == "Invalid token or Expired") {
                        setShowSessionPopupup(true)
                    } else {
                        getAllLeadsData(value)

                    }
                }

            } catch {

            }
        }

    }
    const updateConvData = async (data) => {


        try {
            const payload = {
                "status": data,
                "conversationid": activeconversationItem?.conversationid
            }
            const response = await tmdbApi.UpdateConvData(payload);

            if (response?.statusCode == 200) {
                if (response?.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    conversationCommentsData(saveLeadId)

                }
            }

        } catch {

        }


    }

const handleSelectCheckboxChange=(event)=>{
    setIsChecked1(event.target.checked);
    if(event.target.checked===false){
        setLeadError({...leadError,accountmanager:""})
    }
    console.log("checked------------------------->",event.target.checked)
}
    const handleLeadChange = (e) => {
        GetTimeActivity()
        //  if(e.target.name === "leadname"){

        //     let errors = {...leadError}
        //     errors[e.target.name]="";
        //     setLeadError(errors)
        //  }

        const onlyDigits = e.target.value.replace(/\D/g, "")
        let errors = { ...leadError }
        errors[e.target.name] = "";
        setLeadError(errors)
        setLeadsFormData({ ...leadsFormData, [e.target.name]: e.target.value });
        if (e.target.name === "phone") {
            setLeadsFormData({ ...leadsFormData, phone: onlyDigits })
            setLeadError(prevErrors => ({
                ...prevErrors,
                idc: "",
            }));
        } else {
            if (e.target.name === "companyname") {
                setLeadsFormData({ ...leadsFormData, companyname: [e.target.value] })

            }
        }
        if (e.target.name === "idc") {
            setLeadError(prevErrors => ({
                ...prevErrors,
                phone: "",
            }));
        }

    }

    const handleConversionChange = (e) => {
        GetTimeActivity();
        let errors = { ...leadError };
        errors[e.target.name] = "";
        setLeadError(errors);
        setConversationFormData({ ...conversationFormData, [e.target.name]: e.target.value });

    };


    const handleClientChange = (e) => {
        let errors = { ...leadError }
        errors[e.target.name] = "";
        setLeadError(errors)

        if (e.target.value === "INDIVIDUAL") {
            setAddClientData({
                ...addClientData,
                companyname: leadData?.companyname,
                [e.target.name]: e.target.value

            });

            delete errors.companyname;
            setLeadError(errors);

        } else if (e.target.value === "COMPANY") {
            setAddClientData({
                ...addClientData,
                companyname: "",
                [e.target.name]: e.target.value
            });
            delete errors.companyname;
            setLeadError(errors);
        }
        else {
            setAddClientData({
                ...addClientData,
                [e.target.name]: e.target.value
            });
        }






    }
    const handleSearchLead = (e) => {
        setLeadSearch(e.target.value)

        const dummy = allLeadsData
        const k = dummy?.filter(obj => obj.leadname.toLowerCase().includes(e.target.value.toLowerCase()));
        // setSaveLeadId(k?.[0]?.leadid)

        setTempLeadList(k)
        setFilterLeads(k)


    }
    const handleChangeNotifyData = (e) => {
        const { name, value, checked } = e.target;
        const regex = /^\d*\.?\d{0,2}$/;
        let errors = { ...leadError };

        // Clear the error for the current input field
        errors[name] = "";
        setLeadError(errors);

        // Handle checkboxes
        if (name === 'agreement' || name === 'signednda') {
            setNotifyData({
                ...notifyData,
                [name]: checked
            });
        }
        // Handle commission percentage
        else if (name === "commissionpercentage") {
            if (value === '' || (regex.test(value) && value <= 100)) {
                setNotifyData({
                    ...notifyData,
                    [name]: value
                });
            }
        }
        // Handle other inputs
        else {
            setNotifyData({
                ...notifyData,
                [name]: value
            });
        }
    };




    const clearIconSearch = () => {
        setFlagg("")
        setActiveSearch(false);
        setLeadSearch("")
        setSaveLeadId(allLeadsData?.[0]?.leadid)
        setTempLeadList(allLeadsData)
        setLeadData(allLeadsData?.[0])
        getConversations(allLeadsData?.[0]?.leadid)
        getAllDeals(allLeadsData?.[0]?.clientid)
        setFilterLeads([])
    }
    const handleCommentsChange = (e) => {
        let errors = { ...leadError };
        errors[e.target.name] = "";
        setLeadError(errors);



        setAddComments({ ...addComments, [e.target.name]: e.target.value });

    };
    const handlePhoneIdc = (e) => {
        const { name, value } = e.target;
        setLeadError({})
        if (name === "Idc") {
            setLeadData({ ...leadData, idc: value });
        } else {
            const onlyDigits = value.replace(/\D/g, "");
            setLeadData({ ...leadData, phone: onlyDigits })
        }
    }

    const checkInput = (e) => {
        const onlyDigits = e.target.value.replace(/\D/g, "");
        setLeadError({})
        setPhoneNumber(onlyDigits);
    };
    const checkIdc = (e) => {
        // const errors = { ...leadError,phone:"" }
        // errors[e.target.name] = "";
        setLeadError({})

        setIdc(e.target.value)
    }

    const handleBack = () => {
        setTempLeadList(allLeadsData)
        setActiveSearch(false)
        setLeadSearch("")
        setFlagg("")
    }
    const handleAddClient = async () => {
        setClientPopup(true)
        setAddClientData({ type: "", entity: "", companyname: "" })

    }
    const handleSendEmail = () => {
        setAdminPopup(true)
        setNotifyData({ ...notifyData, accountmanager: userData?.name, companyname: leadData?.companyname, clienttype: "", entity: "", companytype: "", agreement: false, signednda: false, commissiontype: "", commissionpercentage: "" })
        setLeadError({})
    }
    const showContent = () => {
        setIsChecked(!isChecked)
        if (isChecked) {
            updateConvData("open")


        } else {
            updateConvData("close")
            setLeadError({})
        }
    }
    const handleAddData = () => {
        setConversationpopup(true)
        setConversationFormData({ subject: "", leadclassification: "", enquirydate: "", contactdate: "", modeofcommunication: "", additionalcontacts: [], conversationsummary: "" })
        setLeadError({})
    }



    const customNoRecords = () => {
        return (

            <div className="empty-state-body empty-record"  >
                <div className="empty-state__message">
                    <span className="material-icons">summarize</span>
                    <p className="form-check font-size-16">No deals were found for the searched keyword</p>
                </div> </div>
        )
    }

    const customNoRecordsMessage = () => {
        return (

            <div className="empty-state-body empty-record"  >
                <div className="empty-state__message">
                    <span className="material-icons">summarize</span>
                    <p className="form-check font-size-16">No deals were found for the searched keyword</p>
                </div> </div>
        )
    }


    return (
        <>
            {showSessionPopupup && <SessionPopup />}
            <div id="layout-wrapper">
                <Header />
                <Sidebar />

                <div className={isLoading || allLeadsData && allLeadsData?.length > 0 ? "main-content user-management deals clients lead_management" : "main-content deals clients user-management lm_emptypage"}>

                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row mb-4 breadcrumb">
                                <div className="col-lg-12">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1">
                                            <h4 className="mb-2 card-title">LEAD  Management</h4>

                                        </div>
                                        {/* {subValDashboard && subValDashboard.add && subValDashboard.add.display === true &&
                                             <div>
                                                 <button onClick={handleAddDeal} className="btn btn-primary" disabled={subValDashboard && subValDashboard.add && subValDashboard.add.enable === false}>back</button>
                                             </div>
                                         } */}
                                    </div>
                                </div>
                            </div>



                            <div className={allLeadsData && allLeadsData?.length > 0 && conversationData && conversationData?.length > 0 ? "row table-data" : "row table-data empty_conversation"}>
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">


                                            {isLoading ? <TableLoader /> :
                                                (conversationData && conversationData?.length <= 0 && allLeadsData && allLeadsData?.length <= 0) ?
                                                    <div className="welcome_lm">
                                                        <span><img src={image+"orasi/admin/resources/orasiv1/images/add-lead.png"} /></span>
                                                        <p>Welcome to Lead Management. <br></br>Add Contact to get started</p>
                                                        <a className="btn btn-primary" onClick={handleAddLead}>ADD lead</a>
                                                    </div>
                                                    : <>

                                                        <div className="lm_block">
                                                            <div className="contacts">
                                                                <div className={activeSearch ? "header_block search_block" : "header_block"} >
                                                                    {/* ................................. */}
                                                                    {allLeadsData && allLeadsData?.length > 0 && <>
                                                                        {activeSearch ? (
                                                                            <div className="position-relative">
                                                                                <input
                                                                                    type="text"
                                                                                    name="name"
                                                                                    className="form-control"
                                                                                    placeholder="Search Contacts"
                                                                                    value={leadSearch}
                                                                                    onChange={(e) => handleSearchLead(e)}
                                                                                    autoFocus

                                                                                />

                                                                                {clearIcon ? <a
                                                                                    onClick={() => clearIconSearch()} className="material-icons search-icon">clear</a> : <span className="material-icons search-icon">search</span>}
                                                                            </div>
                                                                        ) : (

                                                                            <div className="d-flex align-items-center">
                                                                                <p>contacts</p>
                                                                                <button className="btn" onClick={handleAddLead}>
                                                                                    <span className="material-symbols-outlined">add</span> add
                                                                                </button>
                                                                            </div>
                                                                        )}

                                                                        {activeSearch ? null : <a className="material-icons search-icon" onClick={(e) => handleActiveIcon(e)}>search</a>}
                                                                    </>}



                                                                    {/* ....................................... */}
                                                                </div>
                                                                <div className="lead_details">
                                                                    {tempLeadList.length > 0 ? tempLeadList && tempLeadList?.map((eachLead, index) => {


                                                                        return (
                                                                            <div className={saveLeadId === eachLead?.leadid ? "details active" : "details disable"} key={index} onClick={() => handleAddConversation(eachLead, index)}>
                                                                                <div className="flex-shrink-0 me-3">
                                                                                    <span className="user-badge">{eachLead?.leadname && eachLead?.leadname.split(" ").map(name => name[0]).join("")}</span>
                                                                                </div>
                                                                                <div className="flex-grow-1 align-self-center">
                                                                                    <div className="text-muted">
                                                                                        <h5 className="mb-1">{eachLead?.leadname}</h5>
                                                                                        <p className="mb-0">{eachLead?.companyname}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }) :

                                                                        <div className="no_deals no_contact">
                                                                            <span className="material-symbols-outlined">account_circle_off</span>
                                                                            <p>No Contacts found!</p>
                                                                            <a className="btn btn-primary" onClick={handleBack}>Go Back</a>
                                                                        </div>

                                                                    }
                                                                </div>




                                                            </div>
                                                            {conversationData && conversationData?.length > 0 && <div className="conversation">
                                                                <div className="header_block">
                                                                    <p>CONVERSATIONS</p>
                                                                    {conversationData && conversationData.length > 0 && isLoading <= 0 && <button className={tempLeadList?.length <= 0 || userData?.userid !== leadData?.userid ? "btn disable pe-none" : "btn"} onClick={(e) => handleAddData(e)}><span className="material-symbols-outlined">add</span>add</button>}

                                                                </div>
                                                                <div className="lead_details">
                                                                    {conversationData && conversationData.map((item, index) => {
                                                                        return (
                                                                            <div className={
                                                                                (item?.conversationid !== activeconversationItem?.conversationid)
                                                                                    ? "details disable"
                                                                                    : ((tempLeadList.length <= 0 || userData.userid !== leadData.userid)
                                                                                        ? "details pe-none disable"
                                                                                        : "details active")
                                                                            }

                                                                                key={conversationData._id} onClick={() => handleConversationClick(item, index)} >

                                                                                <div className="flex-shrink-0 me-3">
                                                                                    <span className="user-badge">
                                                                                        <span className="material-symbols-outlined">
                                                                                            {item?.modeofcommunication === "Video Cal" ? 'videocam' : item?.modeofcommunication === "Phone" ? "call" : item?.modeofcommunication === "Messaging" ? 'sms' : item?.modeofcommunication === "Email" ? 'Email' : ""}
                                                                                        </span>
                                                                                    </span>
                                                                                </div>

                                                                                <div className="flex-grow-1 align-self-center">
                                                                                    <div className="text-muted">
                                                                                        <h6 className="mb-1 text-truncate">{item?.subject}</h6>
                                                                                        <p className="mb-0">{item?.leadclassification}</p>
                                                                                    </div>
                                                                                </div>
                                                                                {item?.status === "close" ? <span className="badge badge_closed">closed</span> : <span className="badge">open</span>}

                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>

                                                            </div>}
                                                            {allLeadsData && allLeadsData?.length > 0 && (
                                                                conversationData && conversationData?.length > 0 ?

                                                                    <div className="summary">
                                                                        <div className="header_block">

                                                                            <p>Subject : {activeconversationItem?.subject}</p>
                                                                            <div className="input-field switch-buttons d-flex align-items-center">
                                                                                <label className="switch disable">
                                                                                    <input type="checkbox" checked={isChecked} disabled={tempLeadList <= 0 || userData?.userid !== leadData?.userid} onChange={showContent} name="signednda" />
                                                                                    <span className="slider round">
                                                                                    </span>
                                                                                </label>
                                                                                {activeconversationItem?.status === "close" ? <label className="col-form-label">CLOSED</label> : <label className="col-form-label">{activeconversationItem?.status?.toUpperCase()}</label>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="summary_details">
                                                                            <div className="d-flex align-items-center justify-content-between">
                                                                                <p className="mb-0 conversation_summary">Conversation Summary</p>
                                                                                <p className="mb-0 enquiry_date">Contact Date: <span>{activeconversationItem && moment(activeconversationItem?.contactdate).locale('en-in').format('D MMM YYYY')}</span></p>


                                                                            </div>
                                                                            <p className="conversation_details">{activeconversationItem?.conversationsummary}</p>
                                                                            {activeconversationItem?.additionalcontacts?.length > 0 && <p className="mb-0 conversation_summary additional_contacts">Additional Contacts:</p>}

                                                                            <p class="conversation_details contact_details">

                                                                                {activeconversationItem && activeconversationItem?.additionalcontacts?.map((contact, index) => (
                                                                                    <span key={index}>
                                                                                        {index > 0 && ', '}
                                                                                        {contact?.value}
                                                                                    </span>
                                                                                ))}


                                                                            </p>
                                                                            {activeconversationItem && activeconversationItem?.comments?.map((item, index) => {
                                                                                return (
                                                                                    <div className="communication_details" key={index}>
                                                                                        <p className="day_divider">

                                                                                            <span className="or"> {getDisplayText(item?.created)} </span>

                                                                                        </p>
                                                                                        <div className="details">
                                                                                            <div className="flex-shrink-0 me-3">
                                                                                                <span className="user-badge">
                                                                                                    <span className="material-symbols-outlined"> {item?.modeofcommuniciation === "Video Cal" ? 'videocam' : item?.modeofcommuniciation === "Phone" ? "call" : item?.modeofcommuniciation === "Messaging" ? 'sms' : item?.modeofcommuniciation === "Email" ? 'Email' : ""} </span>
                                                                                                    {/* {item?.modeofcommuniciation === "Video Cal" && <span className="material-symbols-outlined">videocam</span> || item?.modeofcommuniciation === "Phone" && <span className="material-symbols-outlined">call</span> || item?.modeofcommuniciation === "Messaging" && <span className="material-symbols-outlined">sms</span> || item?.modeofcommuniciation === "Phone" && <span className="material-symbols-outlined">call</span> || item?.modeofcommuniciation === "Email" && <span className="material-symbols-outlined">Email</span>} */}
                                                                                                </span>

                                                                                            </div>
                                                                                            <div className="flex-grow-1 align-self-center">
                                                                                                <div className="text-muted">
                                                                                                    <p className="description">{item?.comment}</p>
                                                                                                    <p className="nextflw_up">Next Follow up : <span>{item && moment(item?.nextfollowpdate).locale('en-in').format('D MMM YYYY')}</span></p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })}






                                                                        </div>
                                                                        <>
                                                                            <div className={(isChecked || tempLeadList.length <= 0 || userData?.userid !== leadData?.userid) ? "flwup_comments pe-none disable" : "flwup_comments"}>

                                                                                <textarea id="email" placeholder="Follow Up Comments" name="comment" type="text" className="form-control" spellcheck="false" value={addComments?.comment} onChange={(e) => handleCommentsChange(e)}></textarea>
                                                                                {leadError.comment !== "" && <span className="errormsg enter_comment" style={{ fontWeight: 'bold', color: 'red' }}>{leadError?.comment}</span>}
                                                                                <div className="communication_mode">
                                                                                    <div className="input-field next_flwupdate "><label className="form-label form-label">next follow up Date</label><span className="required">*</span>
                                                                                        <input type="date" id="birthday" name="nextfollowpdate" className="form-control" 
                                                                                        min={moment().format('YYYY-MM-DD')}

                                                                                        // min={new Date().toISOString().split('T')[0]} 
                                                                                        
                                                                                        // value= {moment(new Date(addComments?.nextfollowpdate)).format('YYYY-MM-DD')}
                                                                                            value={moment(addComments?.nextfollowpdate).format('YYYY-MM-DD')}

                                                                                            onChange={(e) => handleCommentsChange(e)} />
                                                                                        {leadError.nextfollowpdate !== "" && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{leadError?.nextfollowpdate}</span>}
                                                                                    </div>
                                                                                    <div className="input-field mode_communication"><label className="form-label form-label">Mode of communication</label><span className="required">*</span>
                                                                                        <select className="form-select" placeholder="Select Featured" name="modeofcommuniciation" value={addComments?.modeofcommuniciation} onChange={(e) => handleCommentsChange(e)}>
                                                                                            <option value="">Select</option>
                                                                                            <option value="Email">Email</option>
                                                                                            <option value="Phone">Phone</option>
                                                                                            <option value="Messaging">Messaging</option>
                                                                                            <option value="Video Cal">Video Call</option>
                                                                                        </select>


                                                                                        {leadError.modeofcommuniciation !== "" && <span className="errormsg " style={{ fontWeight: 'bold', color: 'red' }}>{leadError?.modeofcommuniciation}</span>}
                                                                                    </div>
                                                                                    <div className="flp_btn">
                                                                                        <label className="form-label form-label">add</label>
                                                                                        <a className={isChecked || btnLoader === "added" || userData?.userid !== leadData?.userid ? "btn btn-primary pe-none" : "btn btn-primary"} onClick={() => handleAddComments()}>
                                                                                            {btnLoader === "added" ? (
                                                                                                <img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />
                                                                                            ) : null}
                                                                                            add
                                                                                        </a>

                                                                                    </div>

                                                                                </div>
                                                                            </div>

                                                                        </>



                                                                    </div> :

                                                                    <div className="summary">
                                                                        <div className="header_block">
                                                                            <div className="d-flex align-items-center">
                                                                                <p>CONVERSATIONS</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="summary_details">
                                                                            <span><img src={image+"orasi/admin/resources/orasiv1/images/add-conversation.png"} /></span>
                                                                            <p>It’s the beginning of conversations of the contact .<br></br>Click ‘Add’ to start one.</p>
                                                                            <a className={`btn btn-primary out-line ${tempLeadList <= 0 || userData?.userid !== leadData?.userid ? 'pe-none disable' : ''}`} onClick={(e) => handlAddConversation(e)}>ADD CONVERSATION</a>
                                                                        </div>
                                                                    </div>
                                                            )}

                                                            <>
                                                                {/* {allLeadsData && allLeadsData?.map((item) => {
                                                                     return (
                                                                         saveLeadId === item?.leadid && (
                                                                             <div className="profile_details " key={item?.leadid}>
                                                                                 <div className="details">
                                                                                     <div className="flex-shrink-0 me-3">
                                                                                         <span className="user-badge">
                                                                                             {item?.leadname && item?.leadname.split(" ").map(name => name[0]).join("")}
                                                                                         </span>
 
 
                                                                                     </div>
                                                                                     <div className="flex-grow-1 align-self-center">
                                                                                         <div className="text-muted">
                                                                                             <h5 className="mb-1">{item?.leadname}</h5>
                                                                                             <p className="mb-0">{item?.companyname}</p>
                                                                                         </div>
                                                                                     </div>
                                                                                     {leadData?.type && leadData?.type?.length > 0 ? (
                                                                                         <span className="badge ">{leadData?.type}</span>
                                                                                     ) : (
                                                                                         <button className="btn" onClick={() => handleAddClient()}>
                                                                                             <span className="material-symbols-outlined">add</span>CLIENT
                                                                                         </button>
                                                                                     )}
 
 
 
                                                                                 </div>
                                                                                 <div className="profile_update">
                                                                                     <div className="input-field">
                                                                                         <label className="col-form-label">Email</label>
                                                                                         <input className="form-control" placeholder="Enter Email" type="text" name="emailid" id="example-text-input" value={item?.emailid} disabled />
                                                                                     </div>
                                                                                     <div className="input-field">
                                                                                         <label className="col-form-label">phone</label>
                                                                                         <div className="country-code">
 
                                                                                             <select name="Idc" className="colorselect capitalize" disabled={tempLeadList <= 0} value={idc} onChange={(e) => checkIdc(e)}>
                                                                                                 <option value="">Select</option>
                                                                                                 {countries && countries?.length > 0 && countries?.map((task, i) => {
                                                                                                     return (
                                                                                                         <><option key={i} value={task?.alpha3}>{task?.alpha3 + task?.countrycode}</option></>
                                                                                                     )
                                                                                                 }
                                                                                                 )}
                                                                                             </select>
 
                                                                                             <input className="form-control contact-number" type="tel" name="phonenumber" placeholder="Enter"
                                                                                                 value={phonenumber} disabled={tempLeadList <= 0} onChange={(e) => checkInput(e)}
                                                                                                 maxLength="10" id="example-tel-input" />
 
                                                                                         </div>
                                                                                         {leadError?.Idc && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{leadError?.Idc}</span>}
                                                                                         {leadError?.phonenumber && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{leadError?.phonenumber}</span>}
 
                                                                                     </div>
                                                                                  
                                                                                     <a className={`btn btn-primary out-line ${tempLeadList <= 0 ? 'pe-none' : ''}`} disabled={tempLeadList <= 0} onClick={() => updateLeadData("update")}>{btnLoader === "update" ? (<img src="https://d9nwtjplhevo0.cloudfront.net/orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg" className="loading-icon" />) : null}update</a>
                                                                                 </div>
                                                                             </div>
                                                                         )
                                                                     );
                                                                 })} */}
                                                                {
                                                                    saveLeadId === leadData?.leadid && (
                                                                        <div className="profile_details">
                                                                            <div className="details">
                                                                                <div className="flex-shrink-0 me-3">
                                                                                    <span className="user-badge">
                                                                                        {leadData?.leadname && leadData?.leadname.split(" ").map(name => name[0]).join("")}
                                                                                    </span>


                                                                                </div>
                                                                                <div className="flex-grow-1 align-self-center">
                                                                                    <div className="text-muted">
                                                                                        <h5 className="mb-1">{leadData?.leadname}</h5>
                                                                                        <p className="mb-0">{leadData?.companyname}</p>
                                                                                    </div>
                                                                                </div>
                                                                                {leadData?.type && leadData?.type?.length > 0 ? (
                                                                                    <span className="badge ">{leadData?.type}</span>
                                                                                ) : (
                                                                                    <button className={tempLeadList?.length <= 0 || userData?.userid !== leadData?.userid ? "btn pe-none disable" : "btn"} onClick={() => handleAddClient()}>
                                                                                        <span className="material-symbols-outlined">add</span>CLIENT
                                                                                    </button>
                                                                                )}
                                                                                {userData?.type === "ACCOUNTMANAGER" || userData?.type === "MANAGER" ? <button className={leadData?.notifyAdminSendEmail === true || userData?.userid !== leadData?.userid ? "btn email_admin disable pe-none" : "btn email_admin"} onClick={() => handleSendEmail()}>
                                                                                    <span className="material-symbols-outlined">send</span>Email to Admin</button> : null}



                                                                            </div>
                                                                            <div className="profile_update">
                                                                                <div className="input-field">
                                                                                    <label className="col-form-label">Email</label>
                                                                                    <input className="form-control" placeholder="Enter Email" type="text" name="emailid" id="example-text-input" value={leadData?.emailid} disabled />
                                                                                </div>
                                                                                <div className="input-field">
                                                                                    <label className="col-form-label">phone</label>
                                                                                    <div className="country-code">

                                                                                        <select name="Idc" className="colorselect capitalize" disabled={tempLeadList <= 0 || userData?.userid !== leadData?.userid} value={leadData?.idc} onChange={(e) => handlePhoneIdc(e)}>
                                                                                            <option value="">Select</option>
                                                                                            {countries && countries?.length > 0 && countries?.map((task, i) => {
                                                                                                return (
                                                                                                    <><option key={i} value={task?.alpha3}>{task?.alpha3 + task?.countrycode}</option></>
                                                                                                )
                                                                                            }
                                                                                            )}
                                                                                        </select>

                                                                                        <input className="form-control contact-number" type="tel" name="phonenumber" placeholder="Enter phone number" value={leadData?.phone} disabled={tempLeadList <= 0 || userData?.userid !== leadData?.userid} onChange={(e) => handlePhoneIdc(e)}
                                                                                            maxLength="10" id="example-tel-input" />

                                                                                    </div>
                                                                                    {leadError?.Idc && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{leadError?.Idc}</span>}
                                                                                    {leadError?.phonenumber && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{leadError?.phonenumber}</span>}

                                                                                </div>

                                                                                <a className={`btn btn-primary out-line ${tempLeadList <= 0 || userData?.userid !== leadData?.userid ? 'disable pe-none' : ''}`} disabled={tempLeadList <= 0} onClick={() => updateLeadData("update")}>{btnLoader === "update" ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}update</a>
                                                                            </div>
                                                                        </div>
                                                                    )

                                                                }

                                                            </>



                                                        </div>

                                                    </>}


                                            {
                                                !isLoading && allLeadsData?.length > 0 && conversationData?.length > 0 && (
                                                    <>
                                                        {data && data?.length > 0 && clientData?.[0]?.status !== "INACTIVE" && clientData?.[0]?.status !== "REJECT" ? (
                                                            <div className="addl_block">
                                                                <p>deals</p>
                                                                <button onClick={() => AddDeal()} className={tempLeadList?.length <= 0 || userData?.userid !== leadData?.userid ? "btn pe-none disable" : "btn"}>
                                                                    <span className="material-symbols-outlined">add</span>add
                                                                </button>
                                                            </div>
                                                        ) : (leadData && leadData.type?.length > 0 && (leadData.type === "BUYER" || leadData.type === "BOTH") && (clientData && clientData?.[0]?.status !== "INACTIVE") && (clientData && clientData?.[0]?.status !== "REJECT")) ? (
                                                            <div className="no_deals">
                                                                <span className="material-symbols-outlined">list_alt</span>
                                                                <p>No Deals found for this client!</p>
                                                                <a className={tempLeadList?.length <= 0 || userData?.userid !== leadData?.userid ? "btn btn-primary pe-none disable" : "btn btn-primary"} onClick={() => AddDeal()}>Add deal</a>
                                                            </div>
                                                        ) : !leadData?.type ? (
                                                            <>
                                                                <div className="no_deals">
                                                                    <span className="material-symbols-outlined">list_alt</span>
                                                                    <p>Deals are not visible until the lead is converted into a client</p>
                                                                    <button className={tempLeadList?.length <= 0 || userData?.userid !== leadData?.userid ? "btn add_client pe-none disable" : "btn add_client"} onClick={() => handleAddClient()}>
                                                                        <span className="material-symbols-outlined">add</span>CLIENT
                                                                    </button>
                                                                </div>

                                                            </>
                                                        ) : (null)}
                                                        {data && data?.length > 0 && (
                                                            < DataTable key={keyForRerender}

                                                                // title=""
                                                                columns={columns}
                                                                // className="table align-middle table-nowrap table-check"
                                                                keyField='_id'
                                                                data={data}
                                                                direction="auto"
                                                                highlightOnHover
                                                                fixedHeaderScrollHeight="300px"
                                                                pagination
                                                                responsive
                                                                persistTableHead
                                                                // selectableRowsVisibleOnly
                                                                striped
                                                                // selectableRowsHighlight
                                                                // selectableRows
                                                                subHeaderAlign="right"
                                                                defaultSortField="name"
                                                                subHeaderWra
                                                                noDataComponent={customNoRecords()}
                                                                paginationTotalRows={data && data.length}
                                                                onChangeRowsPerPage={handlePerRowsChange}
                                                                onChangePage={handlePageChange}
                                                                paginationPerPage={rowsPerPage}
                                                                paginationDefaultPage={currentPageNew}
                                                                paginationRowsPerPageOptions={[15, 25, 50, 75, 100]}
                                                                sortFunction={customSort}

                                                                paginationComponentOptions={{
                                                                    rowsPerPageText: 'Per page:',
                                                                    rangeSeparatorText: 'of',
                                                                    noRowsPerPage: false,
                                                                    selectAllRowsItem: false,
                                                                    selectAllRowsItemText: 'All',
                                                                }}

                                                                progressPending={isLoading}
                                                                progressComponent={<TableLoader />}
                                                            />
                                                        )}
                                                    </>
                                                )
                                            }



                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                    <SweetAlert show={success}
                        custom
                        confirmBtnText="ok"
                        confirmBtnBsStyle="primary"
                        title={"No Results Found"}
                        onConfirm={e => onConfirm()}
                    >
                    </SweetAlert>
                    <SweetAlert show={success}
                        custom
                        confirmBtnText="ok"
                        confirmBtnBsStyle="primary"
                        title={"No Results Found"}
                        onConfirm={e => onConfirm()}
                    >
                    </SweetAlert>
                    <SweetAlert show={updateLead}
                        custom
                        confirmBtnText="ok"
                        confirmBtnBsStyle="primary"
                        title={"Updated SuccessFully"}
                        onConfirm={e => onConfirmUpdate()}
                    >
                    </SweetAlert>
                    <SweetAlert show={showExists}
                        custom
                        confirmBtnText="ok"
                        confirmBtnBsStyle="primary"
                        title={"This Lead had been assigned to another Account Manager."}
                        onConfirm={e => onConfirm1()}
                    >
                    </SweetAlert>
                    <SweetAlert show={tableErrMsg}
                        custom
                        confirmBtnText="ok"
                        confirmBtnBsStyle="primary"
                        title={"Something went wrong. Please reload again."}
                        onConfirm={e => setTableErrMsg(false)}
                    >
                    </SweetAlert>

                    <Modal className="advance-search search-popup al_pop" show={addPopup} onHide={handleCloseAddPopup} backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title>Add Contact</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="input-field">
                                            <label className="col-form-label">Contact Name</label>
                                            <span className="required">*</span>
                                            <input className="form-control" placeholder="Enter Contact Name" type="text" name="leadname"
                                                value={leadsFormData?.leadname} onChange={(e) => handleLeadChange(e)}
                                                id="example-text-input" />
                                            {leadError?.leadname !== "" && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{leadError?.leadname}</span>}
                                        </div>
                                    </div>
                                   
                                    <div className="col-md-6">
                                        <div className="input-field">
                                            <label className="col-form-label">Email ID</label>
                                            <span className="required">*</span>
                                            <CreatableSelect
                                                placeholder='Select'
                                                onChange={(e) => handleChangeEmail(e, 'emailid')}
                                                onFocus={handleRemoveError}
                                                
                                                options={buyerdata && buyerdata.map((eachItem) => { return { value: eachItem.personalemail, phone: eachItem.personalphone, label: eachItem.personalemail, idc: eachItem.idc, name: eachItem.name, companyname: eachItem.companyname, accountmanager: eachItem.companyData?.[0]?.accountmanager } })}
                                                value={leadsFormData && leadsFormData?.emailid && leadsFormData?.emailid?.length > 0 ? conversationFormData?.personalemail?.map((eachItem) => { return { value: eachItem, label: eachItem } }) : ""
                                                }
                                            />
                                            {leadError.emailid !== "" && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{leadError?.emailid}</span>}
                                            {/* <input
             className="form-control"
             placeholder="Enter Email ID"
             type="text"
             name="emailid"
             value={leadsFormData?.emailid}
             onChange={(e) => handleLeadChange(e)}
             id="example-text-input"
         />
         // {leadError.emailid !== "" && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{leadError?.emailid}</span>} */}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-field">
                                            <label className="col-form-label">Company name</label>
                                            <input className="form-control contact-number" name="companyname" type="email" placeholder="Enter Company Name"
                                                value={leadsFormData?.companyname} onChange={(e) => handleLeadChange(e)}
                                                id="example-email-input" />



                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-field">
                                            <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                                            <label className="col-form-label">Account Manager</label>  
                                             <div className="export_title_block"style={{ border: 'none', padding: '0' }}>
                                                                         <div className="export_title" style={{ border: 'none', padding: '0px', margin: '0px 0px 5px 15px' }}
                                                                         >
                                                                  <div className="font-size-11">
                                                               <input className="form-check-input" type="checkbox" style={{width: "14px",height: "14px"}} checked={isChecked1} disabled={userData?.type==="ACCOUNTMANAGER"} onChange={handleSelectCheckboxChange}/>
                                                              </div>
                                                                <p style={{marginLeft: "5px"}}>Assign</p>
                                                                  </div>
                                                                         </div>
                                            </div>
                                           
                                            <select className="form-select" name="accountmanager"  value={userData?.type==="ACCOUNTMANAGER"?userData?.name:leadsFormData?.accountmanager} disabled={userData?.type==="ACCOUNTMANAGER"} onChange={(e) => handleLeadChange(e)}>
                                            <option value="">Select</option>
                                            
{account?.map((item)=>{
    return(
        <option>{item?.username}</option>
    )
})}
                                               
                                               
                                            </select>
                                            {leadError?.accountmanager && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{leadError?.accountmanager}</span>}

                                        </div>
                                    </div>
                                   
                                   
                                    <div className="col-md-6">
                                        <div className="input-field">
                                            <label className="col-form-label">PHONE</label>
                                            <div className="country-code">
                                                <select name="idc" className="colorselect capitalize"
                                                    value={leadsFormData?.idc} onChange={(e) => handleLeadChange(e)}
                                                >
                                                    <option value="">Select</option>
                                                    {countries && countries?.length > 0 && countries?.map((task, i) => {
                                                        return (
                                                            <><option key={i} value={task?.alpha3}>{task?.alpha3 + task?.countrycode}</option></>
                                                        )
                                                    }
                                                    )}
                                                </select>
                                              
                                                <input className="form-control contact-number" type="tel" name="phone" placeholder="Enter Phone Number"
                                                    value={leadsFormData?.phone} onChange={(e) => handleLeadChange(e)}
                                                    maxLength="10" id="example-tel-input" />

                                            </div>
                                            {leadError?.idc && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{leadError?.idc}</span>}
                                            {leadError?.phone && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{leadError?.phone}</span>}

                                        </div>
                                    </div>


                                    <div className="col-md-6">
                                        <div className="input-field">
                                            <label className="col-form-label">Contact Classification</label> <span className="required">*</span>
                                            <select className="form-select" name="leadclassification" value={leadsFormData?.leadclassification} onChange={(e) => handleLeadChange(e)}>
                                                <option value="">Select</option>

                                                <option>Awareness</option>
                                                <option>Interest</option>
                                                <option>Desire</option>
                                                <option>Confirmation</option>
                                                <option>Conversion</option>
                                            </select>
                                            {leadError?.leadclassification && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{leadError?.leadclassification}</span>}

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </Modal.Body>
                        {/* <Modal.Body className="border-top">
                             <div>
                                 <div className="row">
                                    
 
 
                                 </div>
                             </div>
                         </Modal.Body> */}
                        <Modal.Footer>
                            <div className="adv_footer">

                                <button className="fill_btn yellow-gradient reset-btn" onClick={clearLeadsData}><span className="material-icons-outlined">sync</span>Reset</button>

                                <button onClick={(e) => handleValidate(e)} className={btnLoader === "adding" && "pe-none"}
                                // className={`fill_btn yellow-gradient float-end ${Object.keys(searchPayload).length == 0 ? "disable" : ""}`} disabled={Object.keys(searchPayload).length == 0} 
                                >
                                    {btnLoader === "adding" ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}   ADD
                                </button>

                                <div className="clearfix"></div>
                            </div>
                        </Modal.Footer>
                    </Modal>
                    <Modal className="advance-search search-popup" show={popup} onHide={handleClosePopup} backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title>Advanced Search</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="input-field">
                                            <label className="col-form-label">NAME</label>
                                            <input className="form-control" placeholder="Enter Name" type="text" name="clientname"
                                                value={dealsadvSearch?.clientname} onChange={(e) => handleLeadChange(e)}
                                                id="example-text-input" />

                                        </div>
                                    </div>



                                    <div className="col-md-6">
                                        <div className="input-field">
                                            <label className="col-form-label">Type</label>

                                            <select className="form-select" name="clienttype"
                                                value={dealsadvSearch?.clienttype} onChange={(e) => handleLeadChange(e)}
                                            >
                                                <option value="">Select Type</option>
                                                <option>BUYER</option>
                                                <option>SELLER</option>
                                                <option>BOTH</option>
                                            </select>

                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input-field">
                                            <label className="col-form-label">ENQUIRED ON</label>
                                            <input id="rights1" name="created" placeholder="dd-mm-yyyy" type="date" className="form-control"
                                                value={dealsadvSearch?.created} onChange={(e) => handleLeadChange(e)} max={new Date()?.toISOString()?.split('T')[0]}
                                            />
                                        </div>
                                    </div>


                                    <div className="col-md-6">
                                        <div className="input-field">
                                            <label className="col-form-label">FOLLOWED ON</label>
                                            <input id="rights1" name="updated" placeholder="dd-mm-yyyy" type="date" className="form-control"
                                                value={dealsadvSearch?.updated} onChange={(e) => handleLeadChange(e)} max={new Date()?.toISOString()?.split('T')[0]}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-field">
                                            <label className="col-form-label">comments</label>
                                            <input className="form-control" placeholder="Enter Comments" type="text" name="comments"
                                                value={dealsadvSearch?.comments} onChange={(e) => handleLeadChange(e)}
                                                id="example-text-input" />

                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input-field">
                                            <label className="col-form-label">Status</label>

                                            <select className="form-select" name="status"
                                                value={dealsadvSearch?.status} onChange={(e) => handleLeadChange(e)}
                                            >
                                                <option value="">Select Status</option>
                                                <option value="NEW">NEW</option>
                                                <option value="INPROGRESS">INPROGRESS</option>
                                                <option value="PARTIALLY CLOSED">PARTIALLY CLOSED</option>
                                                <option value="CLOSED">CLOSED</option>
                                                <option value="SUCCESSFULLY CLOSED">SUCCESSFULLY CLOSED</option>
                                            </select>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>

                            <div className="adv_footer">
                                <button className="fill_btn yellow-gradient reset-btn"><span className="material-icons-outlined">sync</span>Reset</button>

                                <button
                                    onClick={(e) => handleSearch(e, "click")}
                                // className={`fill_btn yellow-gradient float-end ${Object.keys(searchPayload).length == 0 ? "disable" : ""}`} disabled={Object.keys(searchPayload).length == 0} 
                                >
                                    {loaderEnable ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}  SEARCH
                                </button>

                                <div className="clearfix"></div>
                            </div>
                        </Modal.Footer>
                    </Modal>
                    <Modal className="advance-search search-popup al_pop" show={conversationpopup} onHide={handleCloseConversation} backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title>Add Conversation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="input-field">
                                            <label className="col-form-label">Subject</label>
                                            <span className="required">*</span>
                                            <input className="form-control" placeholder="Add Subject" type="text" name="subject"
                                                value={conversationFormData?.subject} onChange={(e) => handleConversionChange(e)}

                                                id="example-text-input" />
                                            {leadError.subject !== "" && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{leadError?.subject}</span>}

                                        </div>
                                    </div>


                                    <div className="col-md-4">
                                        <div className="input-field">
                                            <label className="col-form-label">ENQUIRY DATE</label>
                                            <input id="rights1" name="enquirydate" placeholder="dd-mm-yyyy" type="date" className="form-control" value={conversationFormData?.enquirydate} onChange={(e) => handleConversionChange(e)} />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="input-field">
                                            <label className="col-form-label">CONTACT DATE</label>
                                            <span className="required">*</span>
                                            <input id="rights1" name="contactdate" placeholder="dd-mm-yyyy" type="date" className="form-control" value={conversationFormData?.contactdate} onChange={(e) => handleConversionChange(e)} />
                                            {leadError?.contactdate !== "" && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{leadError?.contactdate}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="input-field">
                                            <label className="col-form-label">Mode of communication</label>
                                            <span className="required">*</span>
                                            <select className="form-select" name="modeofcommunication" value={conversationFormData?.modeofcommunication} onChange={(e) => handleConversionChange(e)}>
                                                <option value="">Select</option>
                                                <option value="Email">Email</option>
                                                <option value="Phone">Phone</option>
                                                <option value="Messaging">Messaging</option>
                                                <option value="Video Cal">Video Call</option>
                                            </select>
                                            {leadError.modeofcommunication !== "" && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{leadError?.modeofcommunication}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="input-field">
                                            <label className="col-form-label">Additional contacts</label>
                                            <CreatableSelect isMulti={true}
                                                placeholder='Select'
                                                onChange={(e) => handleChangeMultiSelect(e, 'additionalcontacts')}
                                                options={buyerdata && buyerdata?.map((eachItem) => { return { value: eachItem?.name, label: eachItem?.name } })}
                                                value={conversationFormData && conversationFormData?.additionalcontacts && conversationFormData?.additionalcontacts?.length > 0 ? conversationFormData?.name?.map((eachItem) => { return { value: eachItem, label: eachItem } }) : []
                                                }
                                            />

                                        </div>

                                    </div>

                                    <div className="col-md-12">
                                        <div className="input-field">
                                            <label className="col-form-label">Conversation Summary</label><span className="required">*</span>
                                            <textarea id="email" placeholder="Add Summary" name="conversationsummary" type="text" className="form-control" spellcheck="true" value={conversationFormData?.conversationsummary} onChange={(e) => handleConversionChange(e)}></textarea>
                                            {leadError.conversationsummary !== "" && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{leadError?.conversationsummary}</span>}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="adv_footer">

                                <button className="fill_btn yellow-gradient reset-btn" onClick={clearConversionData}><span className="material-icons-outlined" >sync</span>Reset</button>

                                <button onClick={(e) => handleConversationData(saveLeadId)} className={loaderEnable && "pe-none"}
                                // className={`fill_btn yellow-gradient float-end ${Object.keys(searchPayload).length == 0 ? "disable" : ""}`} disabled={Object.keys(searchPayload).length == 0} 
                                >
                                    {loaderEnable ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}   ADD
                                </button>

                                <div className="clearfix"></div>
                            </div>
                        </Modal.Footer>
                    </Modal>
                    <Modal className="advance-search search-popup al_pop" show={clientPopup} onHide={handleCloseClient} backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title>Add Client</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="input-field">
                                            <label className="col-form-label">Client Type</label><span className="required">*</span>

                                            <select className="form-select" name="type" value={addClientData?.type} onChange={(e) => handleClientChange(e)}


                                            >
                                                <option value="">Select</option>
                                                <option value="BUYER">BUYER</option>
                                                <option value="SELLER">SELLER</option>
                                                <option value="BOTH">BOTH</option>

                                            </select>
                                            {leadError.type !== "" && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{leadError?.type}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-field">
                                            <label className="col-form-label">ENTITY</label><span className="required">*</span>

                                            <select className="form-select" value={addClientData?.entity} name="entity" onChange={(e) => handleClientChange(e)}

                                            >
                                                <option value="">select entity</option>
                                                <option value="INDIVIDUAL">INDIVIDUAL</option>
                                                <option value="COMPANY">COMPANY</option>

                                            </select>
                                            {leadError?.entity !== "" && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{leadError?.entity}</span>}
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="input-field">
                                            <label className="col-form-label">Company</label><span className="required">*</span>
                                            {addClientData?.entity === "INDIVIDUAL" || addClientData?.entity === "" ? <input className="form-control contact-number" name="companyname" type="text" placeholder="Enter Company Name" value={addClientData.companyname} onChange={(e) => handleClientChange(e)}

                                                id="example-email-input" /> : <CreatableSelect isMulti={false}
                                                    classNamePrefix="select category"
                                                    placeholder='Company Name'
                                                    name="companyname"
                                                    onFocus={handleRemoveCompanyError}
                                                    onChange={(e) => handleChangeCompanySelect(e)}
                                                    options={company && company.map((eachItem) => { return { value: eachItem.companyname, label: eachItem.companyname } })}
                                                    value={addClientData && addClientData?.companyname && addClientData?.companyname.length > 0 ? content?.companyname?.map((eachItem) => { return { value: eachItem, label: eachItem } }) : []}
                                            />}


                                            {leadError.company !== "" && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{leadError?.companyname}</span>}


                                        </div>
                                    </div>



                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="adv_footer">

                                <button className="fill_btn yellow-gradient reset-btn" onClick={clearClientData}><span className="material-icons-outlined">sync</span>Reset</button>

                                <button onClick={() => handleClientAdd("update")}
                                // className={`fill_btn yellow-gradient float-end ${Object.keys(searchPayload).length == 0 ? "disable" : ""}`} disabled={Object.keys(searchPayload).length == 0} 
                                >
                                    {loaderEnable ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}   ADD
                                </button>

                                <div className="clearfix"></div>
                            </div>
                        </Modal.Footer>
                    </Modal>

                    <Modal className="advance-search search-popup al_pop notify_admin" show={adminPopup} onHide={handleCloseAdminPopup} backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title>NOTIFY ADMIN</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="input-field">
                                            <label className="col-form-label">Company Name</label><span className="required">*</span>

                                            <input className="form-control" placeholder="Enter Company Name" type="text" name="companyname" disabled={leadData?.companyname?.[0]?.length > 0}
                                                value={notifyData?.companyname} onChange={(e) => handleChangeNotifyData(e)}
                                                id="example-text-input" />
                                            {leadError?.companyname && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{leadError?.companyname}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-field">
                                            <label className="col-form-label">Contact type</label> <span className="required">*</span>
                                            <select className="form-select" name="clienttype" value={notifyData?.contenttype} onChange={(e) => handleChangeNotifyData(e)}>
                                                <option value="">Select Contact Type</option>
                                                <option>OWNER</option>
                                                <option>DISTRIBUTOR</option>
                                                <option>OWNER & DISTRIBUTOR</option>
                                                <option>BUYER</option>
                                                <option>AGENT</option>
                                                <option>OTHER</option>
                                            </select>
                                            {leadError?.clienttype && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{leadError?.clienttype}</span>}

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-field">
                                            <label className="col-form-label">Company type</label><span className="required">*</span>
                                            <select className="form-select" name="companytype" value={notifyData?.companytype} onChange={(e) => handleChangeNotifyData(e)}>
                                                <option value="">Select</option>

                                                <option >OFFLINE</option>
                                                <option >ONLINE</option>

                                            </select>
                                            {leadError?.companytype && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{leadError?.companytype}</span>}

                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input-field">
                                            <label className="col-form-label">account manager Name</label><span className="required">*</span>
                                            <span className="required">*</span>
                                            <input className="form-control contact-number" disabled name="accountmanager" type="text" placeholder="Enter Account Manager Name"
                                                value={userData?.name}
                                                id="example-email-input" />

                                            {leadError?.accountmanager && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{leadError?.accountmanager}</span>}

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-field">
                                            <label className="col-form-label">Entity</label><span className="required">*</span>
                                            <select className="form-select" name="entity" value={notifyData?.entity} onChange={(e) => handleChangeNotifyData(e)} >
                                                <option value="">Select</option>

                                                <option>COMPANY</option>
                                                <option>INDIVIDUAL</option>

                                            </select>
                                            {leadError?.entity && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{leadError?.entity}</span>}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="mt-2">
                                            <label className="col-form-label">agreement signed</label>
                                            <div className="input-field switch-buttons d-flex align-items-center">
                                                <label className="switch disable">
                                                    <input type="checkbox" name="agreement" checked={notifyData?.agreement} onChange={(e) => handleChangeNotifyData(e)} />
                                                    <span class="slider round"></span>
                                                </label>
                                                <label className="col-form-label">yes</label></div>
                                        </div>
                                        <div className="mt-2">
                                            <label className="col-form-label">nda signed</label>
                                            <div className="input-field switch-buttons d-flex align-items-center">
                                                <label className="switch disable">
                                                    <input type="checkbox" name="signednda" checked={notifyData?.signednda} onChange={(e) => handleChangeNotifyData(e)} />
                                                    <span class="slider round"></span>
                                                </label>
                                                <label className="col-form-label">yes</label></div>
                                        </div>
                                        <div className="mt-2 commission_details">
                                            <label className="col-form-label">commission type</label>

                                            <div className="d-flex">
                                                <div className="font-size-16">
                                                    <input type="radio" id="html" name="commissiontype" checked={notifyData?.commissiontype === 'FIXED'} value="FIXED" onChange={(e) => handleChangeNotifyData(e)} />
                                                    <label className="col-form-label">fixed</label>
                                                </div>
                                                <div className="font-size-16">
                                                    <input type="radio" id="html" name="commissiontype" checked={notifyData?.commissiontype === 'VARIABLE'} value="VARIABLE" onChange={(e) => handleChangeNotifyData(e)} />
                                                    <label className="col-form-label">Variable</label>
                                                </div>


                                            </div>

                                        </div>
                                        <div className="mt-2 percentage">
                                            <label className="col-form-label">commission percentage</label>

                                            <input type="tel" className="form-control" value={notifyData?.commissionpercentage} name="commissionpercentage" id="myPercent" onChange={(e) => {



                                                handleChangeNotifyData(e);
                                            }} />
                                            <span className="material-symbols-outlined">percent</span>

                                        </div>
                                    </div>





                                </div>
                            </div>
                        </Modal.Body>
                        {/* <Modal.Body className="border-top">
                             <div>
                                 <div className="row">
                                     <div className="col-md-12">
                                         <div className="input-field">
                                             <label className="col-form-label">Contact Classification</label> <span className="required">*</span>
                                             <select className="form-select" name="leadclassification" value={leadsFormData?.leadclassification} onChange={(e) => handleLeadChange(e)}>
                                                 <option value="">Select</option>
 
                                                 <option value="Awareness">Awareness</option>
                                                 <option value="Interest">Interest</option>
                                                 <option value="Desire">Desire</option>
                                                 <option value="Confirmation">Confirmation</option>
                                                 <option value="Conversion">Conversion</option>
                                             </select>
                                             {leadError?.leadclassification && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{leadError?.leadclassification}</span>}
 
                                         </div>
                                     </div>
 
 
                                 </div>
                             </div>
                         </Modal.Body> */}
                        <Modal.Footer>
                            <div className="adv_footer">

                                <button className="fill_btn yellow-gradient reset-btn" onClick={clearNotifyData}><span className="material-icons-outlined">sync</span>Reset</button>

                                <button onClick={(e) => handleClickSendEmail(e)} className={btnLoader === "send" && "pe-none"}
                                // className={`fill_btn yellow-gradient float-end ${Object.keys(searchPayload).length == 0 ? "disable" : ""}`} disabled={Object.keys(searchPayload).length == 0} 
                                >
                                    {btnLoader === "send" ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}   SEND EMAIL
                                </button>

                                <div className="clearfix"></div>
                            </div>
                        </Modal.Footer>
                    </Modal>
                    <Footer />
                    <ToastContainer />
                </div>

            </div>
        </>
    );
};

export default AllLeads;


